import React from 'react';

function InterSection(props) {
    return (
        <div className='interSeccion'>
            
        </div>
    );
}

export default InterSection;